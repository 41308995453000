<template>
  <div>
    <HeaderPanel title="Prospect" @sidebar="openSideBar()" :hasDropdown="false" :hasSearch="false" :hasExport="true"
      @export="exportFile()" />

    <div class="my-2 data-total">
      <p class="f-16">
        Total Customer : {{ data.totalCustomer | numeral("0,0") }} ({{ data.totalCustomerPercent.toFixed(2)}}%)
        <br> {{ data.dateStart }} - {{ data.dateEnd }}
      </p>

    </div>

    <div class="bg-white mt-3">
      <b-tabs v-model="tabIndex">
        <b-tab title="Customer List" class="p-3">
          <ProspectTable ref="prospectTable" @daTaTotal="handleStat"></ProspectTable>
        </b-tab>
        <b-tab title="Summary">
          <ProspectChart ref="prospectChart" :tabIndex="tabIndex"></ProspectChart>
        </b-tab>
      </b-tabs>
    </div>
    <SideBarFilter ref="prospectFilter" @filterApplied="handleFilterApplied"></SideBarFilter>
  </div>
</template>

<script>
import HeaderPanel from "@/components/HeaderPanel";
import ProspectTable from "./tabIndex/ProspectTable.vue"
import ProspectChart from "./tabIndex/ProspectChart.vue"
import SideBarFilter from "./tabIndex/SideBarFilter.vue"
export default {
  components: { HeaderPanel, ProspectTable, ProspectChart, SideBarFilter },
  data() {
    return {
      tabIndex: 0,
      data: {
        totalCustomer: 0,
        totalCustomerPercent: 0,
        dateStart: '',
        dateEnd: '',
      },
    };
  },
  methods: {
    async exportFile() {
      this.$refs.prospectTable.exportFile()
    },
    openSideBar() {
      this.$refs.prospectFilter.showFilter()
    },
    handleFilterApplied(filter) {
      this.$refs.prospectTable.getData(filter)
      this.$refs.prospectChart.getData(filter)
    },
    handleStat(data) {
      this.data = data
      this.data.dateStart = this.$moment(this.data.dateStart).format('DD MMM YYYY');
      this.data.dateEnd = this.$moment(this.data.dateEnd).format('DD MMM YYYY');
    },

  },
  watch: {
    tabIndex: {
      handler(newVal) {
        if (newVal === 1) {
          this.$refs.prospectChart.getData();
        }
      },
    }
  }

};
</script>

<style></style>
